import { colorMatrixShader } from "../shaders/color-matrix";

export function vintagePinhole() {
  return () => {
    colorMatrixShader.call(this)([
      0.6279345635605994,
      0.3202183420819367,
      -0.03965408211312453,
      0,
      9.651285835294123,
      0.02578397704808868,
      0.6441188644374771,
      0.03259127616149294,
      0,
      7.462829176470591,
      0.0466055556782719,
      -0.0851232987247891,
      0.5241648018700465,
      0,
      5.159190588235296,
      0,
      0,
      0,
      1,
      0,
    ]);
  };
}
